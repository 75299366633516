let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Kyle Carter",
    "role": "Full Stack Software Engineer | Former SDE at Citi",
    "linkedinId":"https://www.linkedin.com/in/kylekcarter/",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/kylekcarter/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/KyleKCarter",
          "className":"fa fa-github"
        }
      ],
    "aboutme":"After graduating from Texas A&M University and working for a Texas consulting firm in their media operations, I started pursing a career in the tech industry. To further my technological skillset, I enrolled in the DevMountain's Full Stack Web Development Bootcamp. After completion of DevMountain's Bootcamp and working as a Teaching Assistant, Citi Group extended an offer to join their engineering department as a software developer where I have been working for the past four years. During my time at Citi, I have gained experience and knowledge in many different aspects of the development world. ",
    "education":[
      {
        "UniversityName":"DevMountain",
        "specialization":"Full Stack Web Development",
        "MonthOfPassing":"December",
        "YearOfPassing":"2019"
      },
      {
        "UniversityName":"Texas A&M University",
        "specialization":"Bachelor of Education and Human Development in Sports Management",
        "MonthOfPassing":"August",
        "YearOfPassing":"2018",
        "Achievements":"Minor in Business Administration"
      }
    ],
    "work":[
        {
        "CompanyName":"Citi",
        "specialization":"Software Engineer",
        "MonthOfLeaving":"April 2020 - ",
        "YearOfLeaving":"June 2024",
        "Description":"- Develop and maintain web and mobile programs throughout Citi applications.",
        "Description2":"- Identify and analyze problems and provide evaluations of system processes within the application.",
        "Description3":"- Work successfully with multiple different teams across Citi Technology to provide customers with the best product possible.",
        "Description4":"- Became Team Lead for multiple projects currently in use by customers on the Citi Website.",
      },
      {
        "CompanyName":"DevMountain",
        "specialization":"Mentor",
        "MonthOfLeaving":"January 2020 - April 2020",
        "Description": "- Mentor students with web development tools such as HTML, CSS, JavaScript, React.js, Redux, Node.js, SQL, Express.js, and other tools.",
        "Description2": "- Support students in their learning by answering questions.",
        "Description3": "- Advised students with their approaches to problems and tasks they needed to solve.",
        "Description4": "- Assisted the lead instructor within the classroom and lab."
      },
      {
        "CompanyName":"DESK",
        "specialization":"Web Developer (Personal Project)",
        "MonthOfLeaving":"November",
        "YearOfLeaving":"2019",
        "Tech": "React | Redux | Passport | NodeMailer",
        "Description":"DESK is an application that integrates several different entertainment services into one, allowing users to switch between different services with ease.",
        "Description2": "- Built a full-stack web application in 2 weeks using the SERN stack. (SQL, Express, React, and Node)",
        "Description3": "- Won the best technology implemented in my class."
      },
      {
        "CompanyName":"Movie Quote Generator",
        "specialization":"Web Developer (Personal Project)",
        "MonthOfLeaving":"October",
        "YearOfLeaving":"2019",
        "Tech": "React | Express | Node",
        "Description":"My first web application, but without a database, developed at DevMountain.",
        "Description2": "- This project was developed in less than a week using React, Express, and Node.",
        "Description4": "- Other technologies used were the successful implementation of many RESTful APIs."
      },
      {
        "CompanyName":"Uplink Technologies Group",
        "specialization":"IT Support",
        "MonthOfLeaving":"April 2019 - ",
        "YearOfLeaving":"September 2019",
        "Description":"- Assisted in hardware installations in medical offices around the Dallas area.",
        "Description2":"- Performed software installations in medical offices around the Dallas area.",
        "Description3":"- Helped with the onboarding process for new computer systems.",
        "Description4":"- Assist with the installation of cable for new as well as older computers and servers.",
      },
      {
        "CompanyName":"RRG Consulting",
        "specialization":"Director of Media Relations",
        "MonthOfLeaving":"May 2018 - ",
        "YearOfLeaving":"February 2019",
        "Description":"- In charge of all media including social media accounts, website design, podcast, photo, and video editing.",
        "Description2":"- Head of the Sports division, responsible for the development and sales of all sports programs.",
        "Description3":"- Created a sales mindset by pursuing opportunities and collecting clients through events and resources.",
        "Description4":"- Improved multitasking skills, creative mindset, and gained sales experience.",
      }
    ],
    "skills":[
      {
        "skillname": "JavaScript"
      },
      {
        "skillname": "TypeScript"
      },
      {
        "skillname":"HTML"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      },
      {
        "skillname": "Angular"
      },
      {
        "skillname":"Nodejs / Express"
      },
      {
        "skillname":"SQL"
      },
      {
        "skillname":"Redux"
      },
      {
        "skillname":"GIT"
      }

    ],
    "portfolio":[
      {
        "name":"DESK",
        "description":"Personal Project",
        "tech": "React | Redux | Passport | NodeMailer",
        "github": "https://github.com/KyleKCarter/DES",
        "imgurl":"images/deskLanding.png"
      },
      {
        "name":"Movie Quote Generator",
        "description":"NoDB Project",
        "tech": "React | Express | Node",
        "github": "https://github.com/KyleKCarter/NoDB_project",
        "imgurl":"images/movieQuoteGenerator.png"
      },
      {
        "name":"Git Hub Repositories",
        "description":"My Repositories",
        "github": "https://github.com/KyleKCarter?tab=repositories",
        "imgurl":"images/profile_pic.png"
      }
    ]
  }
  
  export default resumeData
